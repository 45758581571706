import Vue from 'vue';
import axios from 'axios';
import * as ACTIONS from '../actions';

const vue = new Vue();

const state = {};

const getters = {};

const actions = {

    [ACTIONS.CREATE_NEW_FIN_PLAN]: ({getters}, data) => {
        return new Promise((resolve, reject) => {
            return axios
                .post(getters.api.finplan.finplan, data)
                .then(res => {
                    resolve(res.data.data);
                    vue.$notifySuccess({text: 'Success'});
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err.response);
                });
        });
    },
    [ACTIONS.UPDATE_FIN_PLAN]: ({getters}, data) => {
        return new Promise((resolve, reject) => {
            return axios
                .put(getters.api.finplan.finplan + '/' + data.id, data)
                .then(res => {
                    resolve(res.data.data);
                    vue.$notifySuccess({text: 'Updated'});
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err.response);
                });
        });
    },
    [ACTIONS.DELETE_FIN_PLAN]: ({getters}, id) => {
        return new Promise((resolve) => {
            return axios
                .delete(getters.api.finplan.finplan + '/' + id)
                .then(res => {
                    resolve(res.data.data);
                    vue.$notifySuccess({text: 'Deleted'});
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.ADD_NEW_PERIOD]: ({getters}, data) => {
        return new Promise((resolve, reject) => {
            return axios
                .post(getters.api.finplan.string + '/' + data.finplan_id, data)
                .then(res => {
                    resolve(res.data.data);
                    vue.$notifySuccess({text: 'Success'});
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err.response);
                });
        });
    },

    [ACTIONS.GET_FIN_PLAN_BY_DEAL]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.finplan.finplan, {params:params})
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.DELETE_FIN_PLAN_PERIOD]: ({getters}, id) => {
        return new Promise((resolve) => {
            return axios
                .delete(getters.api.finplan.data + '/' + id)
                .then(res => {
                    resolve(res.data.data);
                    vue.$notifySuccess({text: 'Deleted'});
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.UPDATE_FIN_PLAN_PERIOD]: ({getters}, data) => {
        return new Promise((resolve, reject) => {
            return axios
                .put(getters.api.finplan.string + '/' + data.finplan_id + '/' + data.id, data)
                .then(res => {
                    resolve(res.data.data);
                    vue.$notifySuccess({text: 'Updated'});
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err.response);
                });
        });
    },

    [ACTIONS.GET_FIN_PLANS_PERIODS]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.finplan.data, {params:params})
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.DOWNLOAD_PERIODS_CSV]: ({getters}, params) => {
        return axios
            .get(getters.api.finplan.csv, {params: params})
            .then(res => {
                window.location.href = res.data.data.link;
            })
            .catch(err => {
                vue.$catchProceed(err);
            });
    },

    [ACTIONS.IS_NEED_FIN_PLAN]: ({getters}, deal_id) => {
        return new Promise((resolve) => {
            return axios
              .get(getters.api.finplan.deal + '/' + deal_id)
              .then(res => {
                  resolve(res.data.data);
              })
              .catch(err => {
                  vue.$catchProceed(err);
              });
        });
    },

};

const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations,
}
