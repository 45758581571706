import Vue from 'vue';
import Vuex from 'vuex';

import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(VueAxios, axios);
Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;

Vue.use(Vuex);

import api from './modules/api';
import article from './modules/article';
import contact from './modules/contact';
import dashboard from './modules/dashboard';
import filter from './modules/filter';
import geoGroup from './modules/geo-group';
import lead from './modules/lead';
import report from './modules/report';
import tag from './modules/tag';
import team from './modules/team';
import news from './modules/news';
import user from './modules/user';
import partner from './modules/partner'
import partner_contact from './modules/partner_contact'
import partner_groups from './modules/partner_groups'
import deals from './modules/deals'
import payments from './modules/payments'
import zid from './modules/zid'
import deals_apps from './modules/deals_apps'
import icons from './modules/icons'
import communication from './modules/communication'
import log from "./modules/log";
import contracts from "./modules/contracts";
import smm_titles from "./modules/smm_titles";
import tickets from "./modules/tickets";
import analytics from "./modules/analytics";
import seo from "./modules/seo";
import retention from "./modules/retention";
import mirrors from "./modules/mirrors";
import partner_tickets from "./modules/partner_tickets";
import reminders from "./modules/reminders";
import parser from "./modules/parser";
import mobicash from "./modules/mobicash";
import affiliate from "./modules/affiliate";
import promocode from "./modules/promocode";
import manuals from "./modules/manuals";
import documentation_report from "./modules/documentation_report";
import placements from "./modules/placements";
import affiliate_stats from "./modules/affiliate_stats";
import lead_request from "./modules/lead_request";
import settings from "./modules/settings";
import finplan from "./modules/finplan";

const debug = process.env.NODE_ENV !== 'production';
const store = new Vuex.Store({
    modules: {
        api,
        article,
        contact,
        dashboard,
        filter,
        geoGroup,
        lead,
        news,
        report,
        tag,
        team,
        user,
        partner,
        partner_contact,
        partner_groups,
        deals,
        payments,
        zid,
        deals_apps,
        icons,
        communication,
        log,
        contracts,
        smm_titles,
        tickets,
        analytics,
        seo,
        retention,
        mirrors,
        partner_tickets,
        reminders,
        parser,
        mobicash,
        affiliate,
        promocode,
        placements,
        manuals,
        documentation_report,
        affiliate_stats,
        lead_request,
        settings,
        finplan,
    },
    strict: debug,
});

export default store;
